import React from 'react'
import { graphql } from 'gatsby'

import Root from '../components/Root'
import Meta from '../components/Meta'
import Blades from '../components/Blades'

export const query = graphql`
    query getPost($id: String) {
        post: datoCmsPost(id: { eq: $id }) {
            id
            meta {
                publishedAt
            }
            seo {
                description
                title
                twitterCard
                image {
                    url(imgixParams: { w: "1200", h: "630", fit: "crop" })
                }
            }
            title
            slug
            tags
            category {
                categoryName
            }
            featuredImage {
                gatsbyImageData(placeholder: BLURRED)
                fluid {
                    aspectRatio
                    base64
                    height
                    sizes
                    src
                    srcSet
                    width
                }
            }
            animation {
                url
            }
            animationFallbackImage {
                gatsbyImageData
            }
            blades {
                ... on DatoCmsPostHero {
                    model {
                        apiKey
                    }
                    heading
                    description
                    mobileBackgroundImage: backgroundImage {
                        fluid(
                            maxWidth: 600
                            maxHeight: 800
                            imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    showSocialButtons
                }
                ... on DatoCmsPostBody {
                    model {
                        apiKey
                    }
                    rows {
                        scrollToBladeId
                        backgroundColour
                        richContent {
                            blocks {
                                ... on DatoCmsRichContentImage {
                                    originalId
                                    image {
                                        gatsbyImageData(
                                            placeholder: BLURRED
                                            imgixParams: {
                                                w: "670"
                                                h: "380"
                                                fit: "crop"
                                            }
                                        )
                                    }
                                }
                                ... on DatoCmsRichContentVideo {
                                    originalId
                                    richContentVideo {
                                        gatsbyImageData(
                                            placeholder: BLURRED
                                            imgixParams: {
                                                w: "670"
                                                h: "380"
                                                fit: "crop"
                                            }
                                        )
                                        video {
                                            mp4Url
                                        }
                                    }
                                }
                                ... on DatoCmsRichContentYoutube {
                                    originalId
                                    richContentYoutube {
                                        height
                                        provider
                                        providerUid
                                        thumbnailUrl
                                        title
                                        url
                                        width
                                    }
                                }
                                ... on DatoCmsRichContentButton {
                                    originalId
                                    label
                                    scrollToId
                                    internalLink {
                                        ... on DatoCmsPage {
                                            model {
                                                apiKey
                                            }
                                            slug
                                        }
                                        ... on DatoCmsCharacter {
                                            model {
                                                apiKey
                                            }
                                            slug
                                        }
                                        ... on DatoCmsPost {
                                            model {
                                                apiKey
                                            }
                                            slug
                                        }
                                    }
                                    external
                                    externalLink
                                }
                            }
                            links
                            value
                        }
                    }
                }
            }
        }
    }
`

const PostTemplate = ({
    data,
    pageContext: { globalConfig, locale, recordTitle = null },
    ...props
}) => {
    const blades = data?.post?.blades || []
    const post = {
        title: data?.post?.title,
        seo: data?.post?.seo,
        tags: data?.post?.tags,
        category: data?.post?.category?.categoryName,
        author: null,
        featuredImage: data?.post?.featuredImage,
        animation: data?.post?.animation,
        animationFallbackImage: data?.post?.animationFallbackImage,
        publishedAt: data?.post?.meta?.publishedAt,
    }

    return (
        <Root {...{ globalConfig, locale, ...props }}>
            <Meta seo={{ recordTitle, ...post?.seo }} />
            <Blades {...{ post, blades, locale, globalConfig }} />
        </Root>
    )
}

export default PostTemplate
